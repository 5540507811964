import React from 'react';
import logo from '../assets/logo-white.png';

function Footer() {
  return (
    <footer className="bg-pattern">
      <div className='w-3/4 mx-auto py-12 text-2xl'>
        <a href={"../"}>
          <img src={logo} alt="Logo" className="mx-auto mb-4 w-64" />
        </a>
        <div className="container mx-auto grid gap-4 grid-cols-1 md:grid-cols-3 mt-8">
          <div className="flex flex-col mb-4 md:mb-0">
            <p className="text-1xl font-bold text-white text-center mb-4">
                Contato
            </p>
            <p className="text-sm text-center text-white">
              <a href="https://api.whatsapp.com/send?phone=5548999631298" target="_blank">
                <i className="fa-brands fa-whatsapp p-2"></i>
                48999631298
              </a>
            </p>
            <p className="text-sm text-center text-white">
              <a href="mailto:contato@hawx.com.br">
                <i className="fa-regular fa-envelope p-2"></i>
                contato@hawx.com.br
              </a>
            </p>
          </div>
          <div className="flex flex-col mb-4 md:mb-0">
            <p className="text-1xl font-bold text-center mb-4 text-white">
              Redes sociais
            </p>
            <div className="flex justify-center">
              <p className="text-sm text-center text-white">
                <a href="https://www.instagram.com/hawxsolucoes/" target="_blank">
                  <i className="fab fa-instagram p-2"></i>
                  Instagram
                </a>
              </p>
            </div>
          </div>
          <div className="flex flex-col">
            <p className="text-1xl font-bold text-center mb-4 text-white">
                Sobre
            </p>
            <p className="text-sm text-center text-white">
              <a href={"sobre-nos"}>
                <i className="fa-regular fa-user p-2"></i>
                Sobre nós
              </a>
            </p>
            <p className="text-sm text-center text-white">
              <a href="https://api.whatsapp.com/send?phone=5548999631298" target="_blank">
                <i className="fa-regular fa-message p-2"></i>
                Fale conosco
              </a>
            </p>
          </div>
        </div>
        <div className="container mx-auto mt-4 text-sm text-center">
          <p className=' text-white'>CNPJ: 55.357.499/0001-80</p>
        </div>
      </div>
    </footer>
  );
}

export default Footer;
