import React from "react";
import Svg from "../util/Svg";
import imagem4 from "../../assets/4.png";

const Platform = () => {
  return (
    <>
      <div className="flex flex-col xl:flex-row xl:pt-24 pb-12 mb-4">
        <div className="w-full xl:w-1/2">
          <p className="text-xl font-medium mb-4 text-green-700">Plataforma</p>
          <p className="text-3xl xl:text-6xl mb-10 font-medium">Escolha onde seu sistema vai ser utilizado</p>
          <div className="flex ">
            <Svg name="check" fill="green" className="mt-1 mr-6" />
            <p className="text-lg">Web</p>
          </div>
          <div className="flex ">
            <Svg name="check" fill="green" className="mt-1 mr-6" />
            <p className="text-lg">Mobile</p>
          </div>
          <div className="flex xl:mb-16">
            <Svg name="check" fill="green" className="mt-1 mr-6" />
            <p className="text-lg">Desktop</p>
          </div>
        </div>
        <div className="flex xl2:w-1/2 xl:w-10/12 z-10 block xl:mt-0 mt-12 mb-16 justify-center xl:justify-end animation-image">
          <img src={imagem4} />
        </div>
      </div>
    </>
  );
};

export default Platform;