import React from "react";
import Site from "./services/Site";
import Software from "./services/Software";

const Budget = () => {
  return (
    <>
      <div className="bg-gray-50">
        <div className="text-black w-3/4 mx-auto">
          <p class="xl:text-6xl text-4xl font-medium xl:text-center xl:pt-40 pt-16 xl:pb-32 pb-8 xl:pb-16">Conheça nossos serviços</p>
          <Software />
          <Site />
        </div>
      </div>
    </>
  );
};

export default Budget;
