import React from "react";

const Values = () => {
  return (
    <>
      <div className="bg-gray-50">
        <div className="text-black w-3/4 mx-auto xl:py-32 py-16">
          <p className="xl:text-6xl text-4xl font-bold mb-12 text-center">Nossos Valores</p>
          <div className="grid gap-16 text-2xl">
            <div>
              <p className="font-bold text-3xl">Qualidade</p>
              <p className="mt-4 text-justify">
                Buscamos a excelência em cada detalhe, criando experiências que são visualmente atraentes, intuitivas e funcionais. Nossa dedicação está em entregar soluções confiáveis, seguras e sempre disponíveis, garantindo que a integridade dos dados seja preservada e que a performance nunca seja comprometida.
              </p>
            </div>
            <div>
              <p className="font-bold text-3xl">Honestidade</p>
              <p className="mt-4 text-justify">
                Agimos com transparência e sinceridade em todas as nossas interações. Nos comunicamos de forma clara e aberta, priorizando a confiança mútua e a credibilidade em todos os nossos relacionamentos.
              </p>
            </div>
            <div>
              <p className="font-bold text-3xl">Aprendizado Contínuo</p>
              <p className="mt-4 text-justify">
                Estamos em constante evolução, sempre buscando melhorias e novas maneiras de inovar, para melhor servir nossos clientes e adaptar-nos às mudanças do mercado.
              </p>
            </div>
            <div>
              <p className="font-bold text-3xl">Sucesso do Cliente</p>
              <p className="mt-4 text-justify">
                O sucesso dos nossos clientes é o nosso sucesso. Estamos focados em entender suas necessidades, superar suas expectativas e oferecer suporte contínuo para que atinjam seus objetivos com excelência.
              </p>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Values;
