import React, { useState } from 'react';
import imagem3 from "../../assets/3.png";

const Software = () => {
  return (
    <>
      <div className="flex flex-col xl:flex-row xl:pt-24 pb-12 mb-4">
        <div className="xl:w-1/2 z-10">
          <p className="text-xl font-medium mb-4 text-green-700">Software</p>
          <p className="text-3xl xl:text-6xl mb-10 font-medium">Transforme sua ideia em realidade</p>
          <p className="text-2xl mt-12 mb-16">Tenha um sistema conforme seus desejos e suas necessidades.</p>
        </div>
        <div className="flex xl2:w-1/2 xl:w-10/12 z-10 justify-center xl:justify-end animation-image">
          <img src={imagem3} />
        </div>
      </div>
    </>
  );
};

export default Software;
