import React from "react";

const Mission = () => {
  return (
    <>
      <div className="bg-gray-50 flex items-center xl:py-32 py-16">
        <div className="text-black w-3/4 mx-auto">
          <p className="xl:text-6xl text-4xl font-bold text-center">Missão</p>
          <p className="text-2xl mt-12 text-justify">Nossa missão na Hawx Soluções é transformar ideias em realidade digital por meio de soluções tecnológicas inovadoras e sob medida. Estamos empenhados em entender profundamente as necessidades de nossos clientes e fornecer produtos e serviços de alta qualidade que impulsionem seus negócios para o sucesso. Buscamos constantemente ultrapassar fronteiras, aprender e evoluir, criando um impacto positivo duradouro no mundo digital.</p>
        </div>
      </div>
    </>
  );
};

export default Mission;
