import React from "react";
import Header from "../components/Header";
import Budget from "../components/Budget";
import Services from "../components/Services";
import Layout from "../components/Layout";
import Doubts from "../components/Doubts";
import Footer from "../components/Footer";
import Whatsapp from "../components/util/Whatsapp";

const Home = () => {
  return (
    <>
      <Header />
      <Budget />
      <Services />
      <Layout />
      <Whatsapp />
      <Footer />
    </>
  );
};

export default Home;
