import React, { useState } from "react";
import logo from '../assets/logo-white.png';

const Header = () => {
  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);

  const toggleMobileMenu = () => {
    setIsMobileMenuOpen(!isMobileMenuOpen);
  };

  return (
    <header className="block px-8 bg-pattern z-50 w-full xl:px-0 xl:bg-transparent xl:absolute">
      <nav className="w-11/12 mx-auto py-6 flex justify-between items-center">
        <div>
          <a href="/" className="inline-block">
            <img src={logo} alt="Logo" className="w-32 xl:w-44" />
          </a>
        </div>
        <div className="md:hidden">
          <button
            className="text-white hover:text-black focus:outline-none p-2"
            onClick={toggleMobileMenu}
          >
            {isMobileMenuOpen ? (
              <i className="fa-solid fa-times text-xl"></i>
            ) : (
              <i className="fa-solid fa-bars text-xl"></i>
            )}
          </button>
        </div>
        <ul className="hidden md:flex space-x-4 items-center">
          <li>
            <a href="/" className="text-white hover:text-black font-semibold">
              Início
            </a>
          </li>
          <li>
            <a href="/sobre-nos" className="text-white hover:text-black font-semibold">
              Sobre Nós
            </a>
          </li>
          <li>
            <a
              href="https://api.whatsapp.com/send?phone=5548999631298"
              target="_blank"
              rel="noopener noreferrer"
              className="text-white hover:text-black font-semibold"
            >
              Contato
            </a>
          </li>
        </ul>
        <div
          className={`fixed inset-0 bg-pattern z-40 transform ${isMobileMenuOpen ? "translate-x-0" : "translate-x-full"
            } transition-transform duration-300 ease-in-out`}
        >
          <button
            className="absolute top-6 right-6 text-white text-3xl focus:outline-none"
            onClick={toggleMobileMenu}
          >
            <i className="fa-solid fa-times"></i>
          </button>

          <ul className="flex flex-col items-center justify-center h-screen">
            <li className="mb-8">
              <a
                href="/"
                className="text-white text-2xl hover:text-black block"
              >
                Início
              </a>
            </li>
            <li className="mb-8">
              <a
                href="/sobre-nos"
                className="text-white text-2xl hover:text-black block"
              >
                Sobre Nós
              </a>
            </li>
            <li className="mb-8">
              <a
                href="https://api.whatsapp.com/send?phone=5548999631298"
                target="_blank"
                rel="noopener noreferrer"
                className="text-white text-2xl hover:text-black block"
              >
                Contato
              </a>
            </li>
          </ul>
        </div>
      </nav>
    </header>
  );
};

export default Header;
