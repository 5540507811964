import React, { useState } from 'react';
import Svg from "../util/Svg";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircleInfo, } from '@fortawesome/free-solid-svg-icons';
import imagem5 from "../../assets/5.png";
import Tooltip from '../util/Tooltip';

const Design = () => {
  return (
    <>
      <div className="flex flex-col-reverse xl:flex-row xl:pt-24 pb-12 mb-16">
        <div className="flex xl2:w-1/2 xl:w-10/12 z-10 xl:block justify-center xl:justify-end animation-image">
          <img src={imagem5} />
        </div>
        <div className="w-full xl:w-1/2 ">
          <p className="text-xl font-medium mb-4 text-green-700">Design</p>
          <p className="text-3xl xl:text-6xl mb-10 font-medium">Tenha um visual único e responsivo</p>
          <div className="flex ">
            <Svg name="check" fill="green" className="mt-1 mr-6" />
            <p className="text-lg">Design system</p>
          </div>
          <div className="flex ">
            <Svg name="check" fill="green" className="mt-1 mr-6" />
            <p className="text-lg block">Ajustes ilumidados</p>
            <Tooltip mensagem="Durante o desenvolvimento">
              <FontAwesomeIcon icon={faCircleInfo} className="text-lg mt-1 ml-2 text-black" />
            </Tooltip>
          </div>
          <div className="flex mb-8 xl:mb-16">
            <Svg name="check" fill="green" className="mt-1 mr-6" />
            <p className="text-lg">Responsividade</p>
          </div>
        </div>
      </div>
    </>
  );
};

export default Design;
